class PlayButton {
    constructor () {
        const audioElements = document.querySelectorAll('[data-audiowrapper]');
        if (audioElements.length === 0) return;

        const playbuttons = document.querySelectorAll('.c-playbutton');

        playbuttons.forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                const pauseIcon = button.querySelector('.c-icon-badge--pause');
                const playIcon = button.querySelector('.c-icon-badge--audio');

                const playerId = button.dataset.player;
                const currentPlayer = document.getElementById(playerId);

                audioElements.forEach((element) => {
                    const player = element.querySelector('audio');
                    if (!player.paused && player !== currentPlayer) {
                        player.pause();
                    }

                    element.querySelector('.c-icon-badge--pause').classList.add('u-visually-hidden');
                    element.querySelector('.c-icon-badge--audio').classList.remove('u-visually-hidden');
                });

                if (currentPlayer.paused) {
                    currentPlayer.play();
                    pauseIcon.classList.remove('u-visually-hidden');
                    playIcon.classList.add('u-visually-hidden');
                } else {
                    currentPlayer.pause();
                    playIcon.classList.remove('u-visually-hidden');
                    pauseIcon.classList.add('u-visually-hidden');
                }

                currentPlayer.addEventListener('ended', function () {
                    currentPlayer.currentTime = 0;
                    pauseIcon.classList.add('u-visually-hidden');
                    playIcon.classList.remove('u-visually-hidden');
                });
            });
        });
    }
}

export default PlayButton;
