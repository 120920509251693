/**
 * cmpservice.js
 *
 * @version 1
 * @copyright 2021 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';

class CmpService {
    getClassName () { return 'CmpService'; }
    constructor () {
        const self = this;
        self.logger = ClassLogger(self, true);
        self.components = {};
    }

    async waitForCmpIsAvailable () {
        return new Promise((resolve, reject) => {
            if (window.UC_UI && window.UC_UI.isInitialized()) {
                resolve();
            } else {
                window.addEventListener('UC_UI_INITIALIZED', function (e) {
                    resolve();
                });
            }
        });
    }

    async waitForExplicitConsent () {
        const self = this;
        return new Promise((resolve, reject) => {
            // First strategy: Waiting for the global CMP event
            const handler = e => {
                self.logger.log('CMP CHANGE', e.detail);
                if (e.detail.type === 'explicit') {
                    window.removeEventListener('QuantyooCmpStatusChange', handler);
                    self.logger.log('Explicit consent status now available (via event)');
                    resolve();
                }
            };
            window.addEventListener('QuantyooCmpStatusChange', handler);

            // Second strategy: Get Services and check for explicit data
            self.waitForCmpIsAvailable().then(() => {
                UC_UI.getServicesBaseInfo().some((service) => {
                    let currentConsent = service.consent.history[0];
                    if (service.consent.history.length > 1) {
                        currentConsent = service.consent.history[service.consent.history.length - 1];
                    }
                    if (currentConsent.type === 'explicit') {
                        self.logger.log('Found explicit consent in services');
                        resolve();
                        return true; // "break" loop on first explicit consent
                    }
                    return false;
                });
            });
        });
    }

    /**
     * Get consent status for IAB Vendor
     * @param {int[]} vendorIds Array of TCF IAB Vendor IDs
     * @returns {Promise<boolean>} consent status
     */
    async getTcfConsents (vendorIds) {
        const self = this;
        if (!Array.isArray(vendorIds)) {
            throw new TypeError('vendorIds must be an array of integers.');
        }
        return new Promise((resolve, reject) => {
            if (typeof __tcfapi !== 'function') {
                reject(new Error('__tcfapi not a function'));
                return;
            }
            __tcfapi('getTCData', 2, (tcData, success) => {
                if (success !== true || typeof tcData !== 'object') {
                    self.logger.error('getTCData not successfull', success, tcData);
                    reject(new Error('getTCData not successful'));
                }
                resolve(tcData.vendor.consents);
            }, vendorIds);
        });
    }

    async areAllConsentsAccepted () {
        if (!UC_UI) {
            throw new Error('CMP not available');
        }
        return UC_UI.areAllConsentsAccepted();
    }

    /**
     * Get consent status for non-IAB Vendor (UC DataProcessingServices)
     * @param {string} vendorName name of the Data Processing Service in the CMP (template ID in v1 accepted as well)
     * @returns {Promise<boolean>} consent status
     */
    async getProcessingServiceConsent (vendorName) {
        const self = this;
        return new Promise((resolve, reject) => {
            self.waitForCmpIsAvailable().then(() => {
                const consent = UC_UI.getServicesBaseInfo().some(service => {
                    if (service.name === vendorName && service.consent.status === true) {
                        return true;
                    }
                    return false;
                });
                if (consent === false) {
                    self.logger.log('NO consent for service vendor ' + vendorName);
                } else {
                    self.logger.log('Got consent for service vendor ' + vendorName);
                }
                resolve(consent);
            });
        });
    }

    addConsentChangeHandler (handler) {
        window.addEventListener('QuantyooCmpStatusChange', handler);
    }
}

export default CmpService;
