/**
 * Slider
 *
 * @version 1.0.0
 * @copyright 2021 SEDA.digital GmbH & Co. KG
 */

import ClassLogger from 'ClassLogger';

import Splide from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';

class Slider {
    getClassName () { return 'Slider'; }
    constructor () {
        const self = this;
        self.options = {};
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.sliders = document.querySelectorAll('.splide');

        if (self.sliders.length === 0) return;

        self.init();
    }

    init () {
        const self = this;
        self.logger.log('Initialized');
        // Config settings per use case
        self.options.program = {
            perPage: 3,
            padding: {
                left: 0,
                right: 0,
            },
            breakpoints: {

                1020: {
                    perPage: 2,
                    padding: {
                        left: 0,
                        right: '15%',
                    },
                },
                860: {
                    perPage: 1,
                    padding: {
                        left: 0,
                        right: '45%',
                    },
                },
                640: {
                    perPage: 2,
                    padding: {
                        left: 0,
                        right: '25%',
                    },
                },
                550: {
                    perPage: 2,
                    padding: {
                        left: 0,
                        right: '20%',
                    },
                },
                400: {
                    perPage: 2,
                    padding: {
                        left: 0,
                        right: '15%',
                    },
                },
            },
        };

        self.options.program_w_text = {
            perPage: 3,
            padding: {
                left: 0,
                right: 0,
            },
            breakpoints: {
                640: {
                    perPage: 2,
                    padding: {
                        left: 0,
                        right: '15%',
                    },
                },
                460: {
                    perPage: 1,
                    padding: {
                        left: 0,
                        right: '35%',
                    },
                },
            },
        };

        self.options.videos = {
            perPage: 3,
            padding: {
                left: 0,
                right: 0,
            },
            breakpoints: {
                640: {
                    perPage: 2,
                    padding: {
                        left: 0,
                        right: '15%',
                    },
                },
                460: {
                    perPage: 1,
                    padding: {
                        left: 0,
                        right: '35%',
                    },
                },
            },
        };

        self.options.aussteller = {
            perPage: 4,
            breakpoints: {
                820: {
                    perPage: 1,
                    grid: {
                        rows: 2,
                        cols: 2,
                        gap: {
                            row: '1rem',
                            col: '1rem',
                        },
                    },
                },
            },
        };

        self.options.news = {
            perPage: 3,
            padding: {
                left: 0,
                right: 0,
            },
            breakpoints: {
                1020: {
                    perPage: 2,
                    padding: {
                        left: 0,
                        right: '25%',
                    },
                },
                800: {
                    perPage: 2,
                    padding: {
                        left: 0,
                        right: 0,
                    },
                },
                500: {
                    perPage: 1,
                    padding: {
                        left: 0,
                        right: '30%',
                    },
                },
            },
        };

        self.options.images = {
            perPage: 1,
        };

        self.options.products = {
            perPage: 3,
            padding: {
                left: 0,
                right: 0,
            },
            focus: 'center',
            updateOnMove: true,
            breakpoints: {
                750: {
                    perPage: 1,
                    padding: {
                        left: '25%',
                        right: '25%',
                    },
                },
                640: {
                    perPage: 1,
                    padding: {
                        left: '30%',
                        right: '30%',
                    },
                },
                639: {
                    perPage: 1,
                    padding: {
                        left: 0,
                        right: '30%',
                    },
                },
            },
        };

        self.slidersInit();
    }

    slidersInit () {
        const self = this;

        Splide.defaults = {
            type: 'slider',
            gap: '20px',
            start: 0,
            rewind: false,
            flickPower: 400,
            perMove: 1,
        };

        const mediumMediaQuery = window.matchMedia('(min-width: 640px)');

        self.sliders.forEach(slider => {
            self.logger.log('Found slider: ' + slider.dataset.slider);

            if (slider.dataset.slider === 'aussteller') {
                new Splide(slider, self.options.aussteller).mount({ Grid });
            }

            if (slider.dataset.slider === 'images') {
                new Splide(slider, self.options.images).mount();
            }

            if (slider.dataset.slider === 'programm') {
                if (mediumMediaQuery.matches && slider.querySelectorAll('.c-slider__item').length <= self.options.program.perPage) {
                    slider.classList.add('not-active');
                } else {
                    new Splide(slider, self.options.program).mount();
                }
            }

            if (slider.dataset.slider === 'programm_w_text') {
                if (mediumMediaQuery.matches && slider.querySelectorAll('.c-slider__item').length <= self.options.program_w_text.perPage) {
                    slider.classList.add('not-active');
                } else {
                    new Splide(slider, self.options.program_w_text).mount();
                }
            }

            if (slider.dataset.slider === 'videos') {
                if (mediumMediaQuery.matches && slider.querySelectorAll('.c-slider__item').length <= self.options.videos.perPage) {
                    slider.classList.add('not-active');
                } else {
                    new Splide(slider, self.options.videos).mount();
                }
            }

            if (slider.dataset.slider === 'news') {
                if (mediumMediaQuery.matches && slider.querySelectorAll('.c-slider__item').length <= self.options.news.perPage) {
                    slider.classList.add('not-active');
                } else {
                    new Splide(slider, self.options.news).mount();
                }
            }

            if (slider.dataset.slider === 'products') {
                if (mediumMediaQuery.matches && slider.querySelectorAll('.c-slider__item').length <= self.options.products.perPage) {
                    slider.classList.add('not-active');
                } else {
                    new Splide(slider, self.options.products).mount();
                }
            }
        });
    }
}

export default Slider;
