/**
 * Embed JS
 *
 * @version 1.0.0
 * @copyright 2021 SEDA.digital GmbH & Co. KG
 */

import ClassLogger from 'ClassLogger';
class EmbedHandler {
    getClassName () { return 'Embed'; }
    constructor (cmp) {
        const self = this;
        self.cmp = cmp;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.init();
    }

    init () {
        const self = this;
        const embeds = document.querySelectorAll('.c-embed');
        if (embeds.length === 0) {
            return;
        }
        self.cmp.waitForExplicitConsent().then(() => {
            embeds.forEach((embed) => {
                const optinButton = embed.querySelector('.c-embed__optinbutton');

                optinButton.addEventListener('click', (e) => {
                    optinButton.classList.add('disabled');
                    self.activateEmbed(embed);
                }, { once: true });

                self.checkEmbedForConsent(embed);
            });
        });
        self.cmp.addConsentChangeHandler(() => {
            const isNotLoaded = document.querySelectorAll('.c-embed:not(.is-loaded)');
            isNotLoaded.forEach((embed) => {
                self.checkEmbedForConsent(embed);
            });
        });
    }

    checkEmbedForConsent (embed) {
        const self = this;
        self.logger.log('Initializing embed', { embed });
        embed.classList.add('is-initialized');
        const vendorName = embed.dataset.embedvendor;

        self.cmp.getProcessingServiceConsent(vendorName).then((consentStatus) => {
            if (consentStatus === true) {
                window.document.documentElement.classList.add('antenneembed-consent-available');
                self.activateEmbed(embed);
            } else {
                self.logger.log('No consent', { vendorName });
            }
        });
    }

    activateEmbed (embed) {
        const self = this;
        self.logger.log('Activating embed', { embed });
        if (embed.classList.contains('is-loaded')) {
            self.logger.warn('Embed already loaded', { embed });
            return;
        }

        const previewArea = embed.querySelector('.c-embed__preview');

        const posts = embed.querySelectorAll('.c-embed__post');
        posts.forEach((post) => {
            const range = document.createRange();
            range.setStart(post, 0);

            const xmp = post.querySelector('xmp');
            const fillArea = post.querySelector('.c-embed__live');

            fillArea.appendChild(range.createContextualFragment(xmp.textContent));
        });

        embed.classList.add('is-loaded');

        previewArea.classList.add('hide');
        previewArea.setAttribute('aria-hidden', 'true');
        window.document.documentElement.classList.add('antenneembed-consent-available');

        const toggleButton = document.querySelector('[data-toggleButton]');
        if (toggleButton) toggleButton.style.display = 'inline-block';
    }
}

export default EmbedHandler;
