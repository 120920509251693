'use strict';

// Imports

import Navigation from './components/_navigation';
import Slider from './components/_slider';
import PlayButton from './components/_playbutton';
import Soundmemory from './components/_soundmemory';
import EmbedHandler from './components/_embed';
import CmpService from './components/_cmpservice';
import ToggleList from './components/_toggleList';

const cmp = new CmpService();

// replace no-js class with js class
document.documentElement.className = document.documentElement.className.replace('no-js', 'js');

/**
 * Returns a promise that resolves once the DOM is ready
 * this is useful for async loaded scripts
 * @returns Promise
 */
const isDomReady = async () => {
    return new Promise((resolve, reject) => {
        if (document.readyState === 'interactive' || document.readyState === 'complete') {
            resolve();
            return;
        }

        document.addEventListener('DOMContentLoaded', () => {
            resolve();
        });
    });
};

isDomReady().then(() => {
    new EmbedHandler(cmp);
    new Navigation();
    new Slider();
    new PlayButton();
    new Soundmemory();
    new ToggleList();
});
