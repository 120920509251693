/**
 * Soundmemory game
 *
 * @version 1.0.0
 * @copyright 2021 SEDA.digital GmbH & Co. KG
 */

import ClassLogger from 'ClassLogger';
import Confetti from 'canvas-confetti';

class Soundmemory {
    getClassName () { return 'Soundmemory'; }
    constructor () {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging

        const memoryObj = document.querySelector('[data-soundmemory]');
        if (memoryObj === null) return;

        self.init(memoryObj);
    }

    init (memoryObj) {
        const self = this;
        self.logger.log('Initializing');

        // hide all but the first page
        self.pages = document.querySelectorAll('[data-page]');
        self.pages.forEach(function (page, index) {
            if (index !== 0) {
                page.classList.add('u-visually-hidden');
            } else {
                page.classList.add('current-page');
            }
        });
        self.currentPageIndex = 0;
        const submitButton = document.querySelector('[data-smbutton]');
        try {
            // Retrieves our trophy images from data attribute
            const trophies = JSON.parse(memoryObj.dataset.soundmemory);

            submitButton.addEventListener('click', (e) => {
                e.preventDefault();
                self.logger.log('Selected option');
                self.playRound(trophies);
            });
        } catch (e) {
            self.logger.error(e.message);
        }
    }

    playRound (trophies) {
        const self = this;
        const main = document.querySelector('[data-smmain');
        const scoreElements = document.querySelectorAll('[data-score]');
        const currentPage = document.querySelector('.current-page');
        const cards = currentPage.querySelectorAll('input');
        const checked = [...cards].filter(card => card.checked);
        const submitButton = document.querySelector('[data-smbutton]');
        const buttonHelp = document.querySelector('[data-smbuttonhelp]');
        const result = document.querySelector('[data-smresult]');
        const resultImage = result.querySelector('img');

        // if no card is checked, show helper text
        if (checked.length === 0) {
            buttonHelp.innerHTML = 'Bitte zuerst eine Karte auswählen';
            buttonHelp.style.display = 'inline-block';
        } else {
            submitButton.classList.toggle('u-disable-pointer-events');
            buttonHelp.style.display = 'none';
            const currentPlayer = currentPage.querySelector('audio');
            currentPlayer.pause();

            cards.forEach((card) => {
                const cardWrapper = card.parentNode.parentNode.parentNode.parentNode;
                cardWrapper.classList.toggle('u-disable-pointer-events');

                if (card.checked) {
                    const cardBack = card.parentNode.parentNode.parentNode.querySelector('[data-back]');
                    const cardInner = card.parentNode.parentNode.parentNode;

                    if (card.dataset.correct === '1') {
                        cardBack.classList.add('c-soundmemory__card-back--correct');
                        cardInner.classList.add('u-rotate');
                        cardInner.addEventListener('transitionend', () => {
                            cardWrapper.classList.add('shake-vertical');
                            cardWrapper.addEventListener('animationend', () => {
                                cardWrapper.classList.remove('shake-vertical');
                            });
                        });

                        scoreElements.forEach((scoreElement) => {
                            let score = scoreElement.dataset.score++;
                            score++;
                            scoreElement.classList.add('pulsate');
                            scoreElement.addEventListener('animationend', () => {
                                scoreElement.classList.remove('pulsate');
                            });
                            scoreElement.querySelector('span').innerHTML = score;
                        });
                    } else {
                        cardBack.classList.add('c-soundmemory__card-back--wrong');
                        cardInner.classList.add('u-rotate');
                        cardInner.addEventListener('transitionend', () => {
                            cardWrapper.classList.add('shake-horizontal');
                            cardWrapper.addEventListener('animationend', () => {
                                cardWrapper.classList.remove('shake-horizontal');
                            });
                        });
                    }
                }
            });
            self.currentPageIndex++;

            if (self.currentPageIndex < 5) {
                self.pages.forEach(function (page, index) {
                    if (index !== self.currentPageIndex) {
                        const cards = page.querySelectorAll('.c-soundmemory__card');
                        const audioElement = page.querySelector('[data-audiowrapper]');

                        setTimeout(() => {
                            cards.forEach((card) => {
                                card.classList.add('slide-out');
                            });

                            audioElement.classList.add('slide-out');
                            audioElement.addEventListener('animationend', () => {
                                page.classList.add('u-visually-hidden');
                                page.classList.remove('current-page');
                                page.classList.remove('slide-in');
                            });
                            submitButton.classList.toggle('u-disable-pointer-events');
                        }, 1500);
                    } else {
                        setTimeout(() => {
                            page.classList.remove('u-visually-hidden');
                            page.classList.add('current-page');
                            page.classList.add('slide-in');

                            const cards = page.querySelectorAll('.c-soundmemory__card');
                            cards.forEach((card) => {
                                card.classList.remove('slide-out');
                            });
                            const audioElement = page.querySelector('[data-audiowrapper]');
                            audioElement.classList.remove('slide-out');
                            submitButton.classList.toggle('u-disable-pointer-events');
                        }, 2000);
                    }
                });
            } else {
                setTimeout(() => {
                    self.logger.log('Ending game');
                    main.classList.add('slide-out');
                    main.addEventListener('animationend', () => {
                        main.classList.add('u-visually-hidden');
                        result.classList.remove('u-visually-hidden');
                        result.classList.add('slide-in');

                        const score = scoreElements[0].dataset.score;
                        self.createSocialMediaLinks(score);

                        let image = '';

                        if (score >= '4') {
                            image = trophies.gold;
                            self.fireworks();
                        } else if (score === '3') {
                            image = image = trophies.silver;
                        } else {
                            image = trophies.bronze;
                        }

                        resultImage.src = `${image}`;
                    });
                }, 2000);
            }
        }
    }

    fireworks () {
        const duration = 15 * 1000;
        const animationEnd = Date.now() + duration;
        const defaults = { startVelocity: 10, gravity: 0.1, spread: 360, ticks: 60, zIndex: 0, disableForReducedMotion: true, colors: ['#DEB654', '#E6001E', '#fff'] };

        function randomInRange (min, max) {
            return Math.random() * (max - min) + min;
        }

        const interval = setInterval(function () {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            const particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            Confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
            Confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
        }, 250);
    }

    createSocialMediaLinks (score) {
        const self = this;
        self.logger.log('Creating social media links');
        const shareObj = document.querySelector('[data-smshareurl]');
        let shareUrl = shareObj.dataset.smshareurl;
        shareUrl = encodeURI(shareUrl + '?score=' + score);
        const shareText = shareObj.dataset.smsharemessage;
        const linkFb = document.querySelector('[data-smfblink]');
        const linkTw = document.querySelector('[data-smtwlink]');
        const hrefFb = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
        const hrefTw = `https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}&hashtags=rideonline`;
        linkFb.setAttribute('href', hrefFb);
        linkTw.setAttribute('href', hrefTw);
    }
}

export default Soundmemory;
