/**
 * Navigation
 *
 * @version 1.0.0
 * @copyright 2020 SEDA.digital GmbH & Co. KG
 */

import ClassLogger from 'ClassLogger';

class Navigation {
    getClassName () { return 'Navigation'; }
    constructor () {
        const self = this;
        self.logger = ClassLogger(self, false); // set second parameter to false to disable logging
        self.init();
    }

    init () {
        const self = this;
        self.lastActiveMenu = null;

        const menuButtons = document.querySelectorAll('[data-menunav]');
        if (menuButtons.length === 0) return;

        self.logger.log('Navigation function is running...');

        menuButtons.forEach(button => {
            // Get content of data attribute which should be the id of the associated Navigation
            const menuId = button.getAttribute('data-menunav');
            const menu = document.getElementById(menuId);

            self.toggleHandler(button, menu);
        });
    }

    toggleHandler (button, menu) {
        const self = this;
        button.addEventListener('click', (e) => {
            self.logger.log('Menu clicked ...');
            e.preventDefault();
            if (menu.getAttribute('aria-expanded')) {
                this.closeMenu(menu, button);
            } else {
                self.closeOpenMenus();
                this.openMenu(menu, button);
            }
        });
    }

    openMenu (menu, button) {
        const self = this;
        self.logger.log('Open menu ...');

        button.classList.add('is-active');

        menu.setAttribute('aria-expanded', true);
        menu.classList.add('is-on-screen');
        menu.classList.remove('is-off-screen');

        self.lastActiveMenu = menu;
    }

    closeMenu (menu, button, hideBackground) {
        const self = this;
        self.logger.log('Close menu ...');
        self.lastActiveMenu = null;

        button.classList.remove('is-active');

        menu.removeAttribute('aria-expanded');
        menu.classList.remove('is-on-screen');
        menu.classList.add('is-off-screen');
    }

    closeOpenMenus () {
        const self = this;
        const menus = document.getElementsByTagName('nav'); // returns HTML Collection
        if (menus.length === 0) return;

        for (let i = 0; i < menus.length; i++) {
            if (menus[i].getAttribute('aria-expanded')) {
                const buttonId = menus[i].getAttribute('aria-labelledby');
                const button = document.getElementById(buttonId);
                self.closeMenu(menus[i], button);
            }
        }
    }
}

export default Navigation;
